<template>
  <div id="app">
    <Header />
    <body id="body" class="has-text-centered"> <!-- Body section -->
      <div>
        <!-- <router-link v-if="homePage()" to="/Budaya">Node Budaya</router-link>
        <router-link v-if="homePage()" to="/Hiburan">Node Hiburan</router-link> -->
        <Graph style="height: 39rem; width: 100%;" v-if="homePage()"/>
      </div>
      <!-- <router-link class="section" to="/Detail">Detail</router-link>  -->
      <router-view class="column"></router-view>
      
    </body>
    <Footer />
  </div>
</template>
 
<script>
import Header from "./components/Header.vue"
import Footer from "./components/Footer.vue"
import Graph from "./components/Graph.vue"

export default {
  name: "App",
  components: {
    Header,
    Footer,
    Graph,
  },
  methods: {
    homePage() {
      if(this.$route.path == "/" || this.$route.path == "/home" ) {
        return true
      } else {
        return false
      }
    }
  },
};
</script>
 
<style>
/* import style bulma */
@import "~bulma/css/bulma.css";
</style>