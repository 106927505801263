import Vue from 'vue'
import VueRouter from 'vue-router'
import Kategori from '@/components/Kategori'
import Graph from '@/components/Graph'

Vue.use(VueRouter)

const routes = [
  {
    path: "/Kategori",
    name: "KategoriList",
    component: Kategori,
  },
  // =========================== KATEGORI ===================================== 
  {
    path: "/Kategori/:id",
    name: "Kategori",
    component: function(){
      return import('./SubKategori.vue')
    },
  },
  {
    path: "/Kategori/SubKategori/:id",
    name: "SubKategori",
    component: function(){
      return import('./Objek.vue')
    }
  },
  {
    path: "/Kategori/SubKategori/Objek/:id",
    name: "Objek",
    component: function(){
      return import('./DetailObjek.vue')
    },
    props: true
  },
  {
    path: "/Kategori/SubKategori/ListObjek/:id",
    name: "ListObjek",
    component: function(){
      return import('./ListObjek.vue')
    }
  },
  {
    path: "/DetailObjek/:id",
    name: "DetailObjek",
    component: function(){
      return import('./DetailObjek.vue')
    },
    props: true
  },
  // {
  //   path: "/ListObjek/:id",
  //   name: "ListObjek",
  //   component: function(){
  //     return import('./ListObjek.vue')
  //   },
  // },
  {
    path: "/AboutUs",
    name: "AboutUs",
    component: function(){
      return import('./AboutUs.vue')
    },
  },
  
  // ================================ GRAPH =====================================
  {
    path: "/home",
    name: "Graph",
    component: Graph,
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router