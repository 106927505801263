import Vue from 'vue'
import App from './App.vue'
import router from './components'

Vue.config.productionTip = false

require("./assets/main.scss")

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')