<template>
    <footer id="footer" class="footer columns content has-text-centered">
      <div class="section content column is-2">
        <a id="link" href="/AboutUs">AboutUs</a>
      </div>
      <div id="judulFooter" class="section column is-8">
        <!-- <p>Portal Objek Budaya Indonesia<br>2021</p> -->
      </div>
      <div class="column is-1">
        <p style="font-size: 0.5em;">Didukung oleh:</p>
        <img id="logoSponsor" src="@/assets/ristekdikti.png">
      </div>
      <div class="column is-1">
        <img id="logoUKDW" src="@/assets/LogoUKDW.png">
      </div>
    </footer>
</template>
<script>
export default {
    name: 'Footer',
}
</script>
<style scoped>
  #link{
    color: white;
  }
  #link:visited{
    color: white;
  }
  #link:active{
    color: white;
  }
</style>