<template>
  <div>
    <body id="body">
      <div id="Sidebar" class="section">
        <aside class="menu">
          <p id="sidebarTitle" class="menu-label">
            KATEGORI
          </p>
          <ul class="menu-list" v-for="(item, i) in items" :key="i.idKategori">
            <router-link id="rlKategori" :to="`/kategori/`+item.idKategori" onclick="window.location.reload();">{{item.namaKategori}}</router-link>
          </ul>
        </aside>
      </div>
      <router-view></router-view>
    </body>
  </div>
</template>

<script>

import axios from "axios"

export default {
    name: "kategori",
    data(){
        return{
            items:[],
        };
    },
    created() {
        this.getAllKategori();
    },
    methods: {
        // Get All Products
      async getAllKategori() {
        try {
            const response = await axios.get(`https://portalapi.alunalun.info/kategori/`);
            this.items = response.data;
        // eslint-disable-next-line no-empty
        } catch (err) {}
      }
    },
}
</script>