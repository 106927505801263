<template>
    <header id="header" class="header section columns"> <!-- Header section -->
      <a href="/" class="columns">
        <div>
          <img id="logoheader" class="image is-128x128" src="@/assets/alunalun.png">
        </div>
        <!-- <div>
          <img id="logoheader" src="@/assets/portalbudaya.jpg">
        </div> -->
      </a>
    </header>
</template>

<script>
export default {
    name: 'Header',
}
</script>