<template>
    <div id="container"></div>
</template>

<script src="https://cdn.anychart.com/releases/8.10.0/js/anychart-core.min.js"></script>
<script src="https://cdn.anychart.com/releases/8.10.0/js/anychart-graph.min.js"></script>

<script>
export default {
    name: 'Graph'
}
</script>

<script>
// import NodeBudaya from '../assets/Nodes/NodeBudaya.png'
anychart.onDocumentReady(function() {
    anychart.licenseKey("alunalun.info-65dc6039-538b4c08");
    var data = {
        nodes: [
            {id: "Budaya",   x:  170, y: 100, url:'/Kategori/1'},
            {id: "Karya Sastra", x:   -100, y: 200, url:'/Kategori/7'},
            {id: "Religi",    x:  400, y: 150, url:'/Kategori/3'},
            {id: "Organisasi",   x:  350, y: 20, url:'/Kategori/4'},
            {id: "Peninggalan Bersejarah",   x:  160, y: 230, url:'/Kategori/5'},
            {id: "Linguistik",   x:  10, y: 120, url:'/Kategori/6'},
            {id: "Museum",   x:  150, y: -30, url:'/Kategori/11'},
            {id: "Teknologi Tradisional",  x: 600, y: 260, url:'/Kategori/2'},
            {id: "Pelaku Seni",   x:  -170, y: 320, url:'/Kategori/8',desc:"Budaya Desc"},
            {id: "Bangunan dan Karya Seni",   x:  -150, y: 40, url:'/Kategori/9'},
            {id: "Hiburan",   x:  -100, y: 430, url:'/Kategori/12'},
            {id: "Peralatan dan Fasilitas",   x:  360, y: 260, url:'/Kategori/14'},
            {id: "Seni Pertunjukan",   x:  10, y: 290, url:'/Kategori/10'},
            {id: "Kuliner",   x:  50, y: 450, url:'/Kategori/13'},
            {id: "Perdagangan",   x:  550, y: 80, url:'/Kategori/15'},
            {id: "Sistem Sosial",   x:  270, y: 450, url:'/Kategori/17'},
            {id: "Sistem Pengobatan",   x:  400, y: 400, url:'/Kategori/16'}
        ],
        edges: [
            {from: "Budaya", to: "Sistem Sosial"},
            {from: "Budaya", to: "Sistem Pengobatan"},
            {from: "Budaya", to: "Peralatan dan Fasilitas"},
            {from: "Budaya", to: "Museum"},
            {from: "Budaya", to: "Organisasi"},
            {from: "Budaya", to: "Perdagangan"},
            {from: "Budaya", to: "Religi"},
            {from: "Budaya", to: "Linguistik"},
            {from: "Budaya", to: "Seni Pertunjukan"},
            {from: "Budaya", to: "Bangunan dan Karya Seni",
            normal: {stroke: {
              color: "#A4161A",
              thickness: "1",
              dash: "10 5",
              lineJoin: "round"
            }}},

            {from: "Hiburan", to: "Pelaku Seni"},

            {from: "Peralatan dan Fasilitas", to: "Teknologi Tradisional"},

            {from: "Peninggalan Bersejarah", to: "Religi",
            normal: {stroke: {
              color: "#A4161A",
              thickness: "1",
              dash: "10 5",
              lineJoin: "round"
            }}},

            {from: "Peralatan dan Fasilitas", to: "Teknologi Tradisional"},

            {from: "Bangunan dan Karya Seni", to: "Pelaku Seni"},

            {from: "Karya Sastra", to: "Linguistik",
            normal: {stroke: {
              color: "#A4161A",
              thickness: "1",
              dash: "10 5",
              lineJoin: "round"
            }}},

            {from: "Seni Pertunjukan", to: "Linguistik",
            normal: {stroke: {
              color: "#A4161A",
              thickness: "1",
              dash: "10 5",
              lineJoin: "round"
            }}},
            {from: "Seni Pertunjukan", to: "Peninggalan Bersejarah"},
            {from: "Seni Pertunjukan", to: "Karya Sastra",
            normal: {stroke: {
              color: "#A4161A",
              thickness: "1",
              dash: "10 5",
              lineJoin: "round"
            }}},
            {from: "Seni Pertunjukan", to: "Pelaku Seni"},
            {from: "Seni Pertunjukan", to: "Hiburan",
            normal: {stroke: {
              color: "#A4161A",
              thickness: "1",
              dash: "10 5",
              lineJoin: "round"
            }}},
            {from: "Seni Pertunjukan", to: "Kuliner"},
    
        ]
    };
    
    var chart = anychart.graph(data);

//======================= LABEL ==============================
    chart.nodes().labels().enabled(true);
    chart.nodes().labels().format("{%id}");
    chart.nodes().labels().fontSize(15);
    chart.nodes().labels().fontWeight(1000);
    chart.nodes().labels().fontColor("#A4161A");
//======================= NODES ==============================
    var nodes = chart.nodes();
    nodes.normal().stroke("#FFFFFF",2);
    nodes.normal().height(70);
    nodes.normal().fill("#A4161A");
    nodes.hovered().height(70);
    nodes.hovered().fill("#A4161A");
//======================= LINES ==============================    
    chart.edges().normal().stroke("#A4161A", 1);
    chart.edges().hovered().stroke("#A4161A", 1);

    chart.layout().type("fixed");
    chart.container("container")
    chart.draw();

    chart.listen('click', function(e) {
    var tag = e.domTarget.tag;
    if (tag) {
      if (tag.type === 'node') {
        // get url from data directly
        var url;
        for (var i = 0; i < data.nodes.length; i++) {
          if (data.nodes[i].id === tag.id) {
            url = data.nodes[i].url;
            break;
          }
        }
        // open url
        window.open(url, '_self');
      }
    }
  });
});
</script>